<template>
  <v-container fill-height class="fondo">
    <v-row align="center" justify="center">
      <v-card style="margin-top: -20px; min-width: 300px"   class="py-6 px-10 text-center" elevation="4"  width="400">
            <img :src="'assets/logo.png'" class="" style="max-height: 100px" />
          <v-row>

            <v-col cols="10">
              <div class="mt-8 text-h6" style="color: #31708f">{{$t('modificarContrasena')}}</div>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="!contraseñasIguales"
                    class="mt-8"
                    icon
                    color="red"
                    @mouseenter="menu = true"
                    @mouseleave="menu = false"
                  >
                    <v-icon>mdi-alert-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-else-if="contraseñasIguales"
                    class="mt-8"
                    icon
                    color="green"
                    @mouseenter="menu = true"
                    @mouseleave="menu = false"
                  >
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-btn>
                </template>

                <v-card width="300" elevation="4">
                  <v-card-subtitle  class="font-weight-bold">{{$t('requisitosContrasena')}} </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <span v-if="!coincidenContraseña" style="font-size:small; color:red" > {{$t('requisitosContrasena1')}}</span>
                        <span v-else-if="coincidenContraseña" style="font-size:small; color:green" >{{$t('requisitosContrasena2')}} </span>
                      </v-col>
                      <v-col cols="12">
                        <span v-if="!caracteresContrasena" style="font-size:small; color:red" >{{$t('requisitosContrasena3')}} </span>
                        <span v-else-if="caracteresContrasena" style="font-size:small; color:green" >{{$t('requisitosContrasena4')}} </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-text-field
            class="mt-2"
            v-model="contrasena"
            type="password"
            :label="$t('contrasenaNueva')"
            required
            @keypress.enter="enfocarContrasena"
            @click:append="verContrasena = !verContrasena"
          >
          </v-text-field>
          <v-text-field
            v-model="contrasenaConfirmada"
            type="password"
            :label="$t('confirmarContrasenaNueva')"
            required

            ref="contrasena"
            @keypress.enter="establecerContrasena"
            @click:append="verContrasenaConfirmada = !verContrasenaConfirmada"
          >
          </v-text-field>
          <v-btn elevation="2" :loading="cambioContrasena" color="primary" :disabled="!contraseñasIguales" class="mt-2" block large @click="establecerContrasena">
            {{$t('guardarContrasena')}}
            <v-icon right dark> mdi-content-save-all </v-icon>
          </v-btn>
      </v-card>
    </v-row>

    <v-dialog v-model="cuadroEmergente.mostrar" max-width="490">
      <v-card>
        <v-card-title class="headline">{{
          cuadroEmergente.titulo
        }}</v-card-title>
        <v-card-text>{{ cuadroEmergente.texto }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="iniciarSesion"
            >{{$t('volverAlInicio')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "../api.js";
export default {
  data() {
    return {
      cambioContrasena:false,
      coincidenContraseña:false,
      contrasenaVacia:false,
      caracteresContrasena:false,
      menu:false,
      contrasena: "",
      contrasenaConfirmada: "",
      correo: "",
      fecha: "",
      hash: "",
      verContrasena: false,
      verContrasenaConfirmada:false,
      cuadroEmergente: {
        mostrar: false,
        titulo: "",
        texto: "",
      },
    };
  },
  computed:{
    contraseñasIguales(){
      if(this.contrasena == this.contrasenaConfirmada && this.contrasena !== "" && this.contrasena.length >= 8)
        return true;
      else{
         return false;
      }

    }
  },
  watch:{
    contrasena(){
      this.comprobarRequisitosContraseña();
    },
    contrasenaConfirmada(){
      this.comprobarRequisitosContraseña();
    }
  },
  methods: {
    //Obtenemos los parametros de la url
    obtenerParametrosUrl() {
      var url = window.location.search;
      var urlParametros = new URLSearchParams(url);
      this.correo = urlParametros.get("correo");
      this.fecha = urlParametros.get("fecha");
      this.hash = urlParametros.get("clave");
      if (!this.correo || !this.fecha || !this.hash)
        this.$router.push({ path: "/" });
    },

    //Comprobamos los requisitos de su contraseña
    comprobarRequisitosContraseña(){

      //Comprobamos si la contraseñas coinciden
      if(this.contrasena == this.contrasenaConfirmada && this.contrasena !== "" && this.contrasenaConfirmada !== "" )
        this.coincidenContraseña = true;
      else
        this.coincidenContraseña = false;


      //Comprobamos si tiene mas de 8 caracteres
      if(this.contrasena.length >= 8 && this.contrasenaConfirmada.length >= 8)
        this.caracteresContrasena = true;
      else
        this.caracteresContrasena = false;

    },

    iniciarSesion(){
        this.$router.push("/");
    },

    //Establecemos la contraseña que indica el usuario
    establecerContrasena() {
      this.cambioContrasena = true;
      api.establecerNuevaContrasena(
        this.correo,
        this.contrasena,
        this.fecha,
        this.hash
      ).then(() => {

        this.cuadroEmergente.mostrar = true;
        this.cuadroEmergente.titulo = this.$t('contrasenaCambiada');
        this.cuadroEmergente.texto = this.$t('contrasenaCambiada2');
      })
      .finally(()=>{
        this.cambioContrasena = false;
      })
    },
    enfocarContrasena() {
      this.$refs.contrasena.focus();
    },
  },
  created() {
    this.obtenerParametrosUrl();
  },
};
</script>

<style>

</style>